<template>
    <div>
        <b-card no-body class="mb-0 mt-4">
            <div class="row justify-content-center my-2">
                <div class="col-md-4">
                    <label>From Date</label>
                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" initial-date="2020-06-16" class="mx-1" v-model="form.from_date" />
                </div>
                <div class="col-md-4">
                    <label>To Date</label>
                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" initial-date="2020-06-16" class="mx-1" v-model="form.to_date" />
                </div>
                <div class="col-md-3">
                    <label>Rank</label>
                    <b-form-input class="mx-1" type="text" placeholder="Rank" v-model="form.rank" />
                </div>
            </div>
            <div class="row justify-content-center mb-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="mt-2 ml-2 btn-icon" @click="onSubmit()"> Update </b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { BFormSpinbutton, BInputGroup, BFormDatepicker, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButtonGroup, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormValidFeedback } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { computed, watch, ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useTrendingList from "./useTrendingList";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BTab,
        Cleave,
        BFormInput,
        BInputGroup,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormSpinbutton,
        apexchart: VueApexCharts,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        flatPickr,
        BFormDatepicker,
        BButtonGroup,
    },
    directives: {
        Ripple,
    },
    setup() {
        //data
        const { storeManualTrends, showManualtrends, updateManualTrends } = useTrendingList();
        const isLoading = ref(false);
        const form = ref({
            from_date: undefined,
            to_date: undefined,
            rank: undefined,
        });
        const validation = ref(true);
        const artistRankList = ref([
            {
                from_date: undefined,
                to_date: undefined,
                rank: undefined,
            },
        ]);
        const onSubmit = async () => {
            let data = form.value;
            let id = router.currentRoute.params.id;
            updateManualTrends(id, data).then((res) => {
                router.push({ name: "apps-trending-list" });
            });
        };
        onMounted(() => {
            let id = router.currentRoute.params.id;
            showManualtrends(id).then((res) => {
                console.log(res);
                form.value.from_date = res.data.from_date;
                form.value.to_date = res.data.to_date;
                form.value.rank = res.data.rank;
            });
        });
        return {
            onSubmit,
            required,
            validation,
            isLoading,
            form,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
